<template>
	<div>
		<div class="tw-flex tw-items-center tw-space-s-7">
			<v-btn
				icon
				dark
				to="/products"
				height="36"
				width="36"
				class="tw-bg-72"
			>
				<v-icon>mdi-chevron-left</v-icon>
			</v-btn>
			<span class="tw-text-xl">{{ $t('productDetails') }}</span>
		</div>

		<product-card-full :product="productDetails" class="tw-mb-16 tw-mt-6" />

		<div>
			<h2 class="tw-text-19px tw-font-semibold">
				{{ $t('similar-products') }}
			</h2>
			<v-row class="mb-3 tw-mt-9 lg:tw-mb-8">
				<v-col
					v-for="(item, i) in $products.data"
					:key="i"
					cols="12"
					sm="6"
					md="4"
					lg="3"
					xl="2"
				>
					<ProductCard
						@onClick="goToSingleProduct"
						:productInfo="item"
						class="tw-flex-none"
					/>
				</v-col>
			</v-row>
		</div>
	</div>
</template>

<script>
import { truncate } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import { numberWithCommas } from '../helpers'

import ProductCard from '../components/customs/ProductCard.vue'
import ProductCardFull from '../components/customs/ProductCardFull.vue'

export default {
	name: 'Productdetails',
	components: {
		ProductCard,
		ProductCardFull,
	},
	data() {
		return {
			productDetails: {
				supplier: {},
				mainCategory: [],
				subCategory: [],
				attachments: [],
				sizes: [],
			},
			selectedImage: 0,
			quantity: 1,
			isUpdating: false,
		}
	},
	watch: {
		'$route.params.id': {
			immediate: true,
			async handler(v) {
				let [err, res] = await this.getProduct({ id: v })
				if (!err) {
					this.productDetails = res.product
					this.quantity = this.cartItem?.quantity || 1
					this.getProducts({
						mainCategory: res.product.mainCategory[0].id,
					})
				}
			},
		},
		'cartItem.quantity': {
			immediate: true,
			handler(v) {
				this.quantity = v
			},
		},
	},
	computed: {
		...mapGetters('cart', ['$cart']),
		...mapGetters('auth', ['$currentUser']),
		...mapGetters('products', ['$products']),
		...mapGetters('category', ['$productCategories']),
		maxQuantity() {
			if (!this.productDetails || !this.productDetails.isSpecifiedQuantity) {
				return Infinity
			}
			return +this.productDetails.quantity
		},
		isInCart() {
			return !!this.cartItem
		},
		cartItem() {
			return this.$cart.products.data.find(cartItem => {
				return cartItem.cartableId == this.productDetails.id
			})
		},
	},
	methods: {
		...mapActions('cart', ['updateCartItem']),
		...mapActions('products', ['getProduct', 'getProducts']),
		async onAddToCart() {
			if (this.isUpdating) return

			this.isUpdating = true
			const [err] = await this.$store.dispatch('cart/addToCart', {
				userId: this.$currentUser.id,
				cartableType: 'product',
				quantity: this.quantity,
				cartableId: this.productDetails.id,
			})
			this.isUpdating = false
			if (!err) this.$toast.success(this.$t('success.addedToCart'))
			else this.$toast.error(this.$t('error.errorAddingCart'))
		},
		async onChangeQuantity(payload) {
			if (!this.isInCart) return this.onAddToCart()

			this.isUpdating = true
			await this.updateCartItem({
				...this.cartItem,
				quantity: payload,
			})
			this.isUpdating = false
		},
		formatPrice(price) {
			console.log(price)
			return numberWithCommas(price)
		},
		getSizes(sizes) {
			return sizes.length ? sizes.map(a => a.name[0]).join('-') : '-'
		},
		goToSingleProduct(id) {
			this.$router.push(`/product/${id}`)
		},
		formatProductName(name) {
			return truncate(name, {
				length: 104,
				separator: '...',
			})
		},
	},
}
</script>
